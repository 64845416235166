import { graphql } from 'gatsby';
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import ContactInfoContainer from '../components/containers/ContactInfoContainer';
import WorksContainer from '../components/containers/WorksContainer';
import Layout from '../components/Layout'
import Seo from '../components/SEO'
import { usePageContext } from '../pageContext';

function ContactsPage({ data }) {

  const languageData = useTranslation()[1].store.data;

  const { lang } = usePageContext();

  const echo = (expression) => {
    return languageData[lang]['worksPage'][expression];
  }

  return (
    <Fragment>
      <Seo title={`Hanniel TSASSE - ${lang == 'fr' ? 'Entrer en contact' : "Let's work togheter !"}`} lang='fr' description={lang == 'fr' ? "Besoin d'un service ? Entrons en contact !" : "Need a service? Let's get in touch!"} />

      <Layout data={data} lang={lang}>
        <ContactInfoContainer lang={lang} />
      </Layout>
    </Fragment>
  )
}

export default ContactsPage;

export const query = graphql`
  query ContactsPageQuery {
    site {
      siteMetadata {
        siteURL
      }
    }
  }`